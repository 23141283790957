<!-- Important: this page is about equal to 7-2-1   -->
<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <b-spinner v-if="!device || !auftragDetail712 || !auftragDetail714" />
            <template v-else>
                <exec-meter-info
                    v-if="devicePool"
                    :mainDevice="device"
                    :order="order"
                    :devicePool="devicePool"
                    :category="'Wechseln'"
                ></exec-meter-info>
                <exec-meter-reading-info
                    type="uninstall"
                    :auftrag-detail712="auftragDetail712"
                    :auftrag-detail714="auftragDetail714"
                    :device="device"
                    class="mt-2"
                ></exec-meter-reading-info>
            </template>
            <email-info-viewer v-if="emailObject" :emailObject="emailObject" :actAuftragDetail="actAuftragDetail" />
            <exec-meter-reading-check
                v-if="
                    actAuftragDetail.status !== 202 &&
                        device &&
                        auftragDetail714 &&
                        auftragDetail712 &&
                        actAuftragDetail
                "
                :device="device"
                :auftrag-detail714="auftragDetail714"
                :auftrag-detail712="auftragDetail712"
                :auftrag-detail-ausbau="actAuftragDetail"
                v-on:isReadingOk="onIsReadingOk"
            >
            </exec-meter-reading-check>
            <b-alert class="mt-2" v-if="compIsStepAlreadyDone" show
                >Zähler bereits ausgebaut. Bitte auf [Weiter] klicken.</b-alert
            >
            <b-alert class="mt-2" v-if="device?.isBreaker" show
                >Warnung: Der demontierte Zähler war ein Breakerzähler. Bitte ersetzen Sie diesen mit einen Smartmeter
                mit Breakerfunktion!</b-alert
            >
            <div class="d-flex mt-3">
                <b-button
                    @click="onClickRemoveNotPossible"
                    :disabled="compErledigtIsDisabled"
                    variant="primary"
                    class="mr-3"
                >
                    Ausbau nicht möglich
                </b-button>
                <b-button
                    class="ml-auto mr-3"
                    @click="onClickMeterStays"
                    :disabled="compErledigtIsDisabled"
                    variant="primary"
                >
                    Bisheriger Zähler bleibt montiert
                </b-button>
                <b-button class="ml-auto" @click="onClickRemoved" :disabled="compErledigtIsDisabled" variant="primary">
                    Ausgebaut
                </b-button>
            </div>
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import EmailInfoViewer from "@/components/execution/EmailInfoViewer.vue";
import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";
import ExecMeterReadingCheck from "@/components/execution/ExecMeterReadingCheck.vue";
import ExecMeterReadingInfo from "@/components/execution/ExecMeterReadingInfo.vue";
import constants from "@/constants/constants";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";
import emailsAPI from "@/services/api/emails.api";

export default {
    name: "Step_7_2_0",
    components: {
        EmailInfoViewer,
        ExecButtonNext,
        ExecButtonPrevious,
        ExecMeterReadingCheck,
        ExecMeterReadingInfo,
        ExecMeterInfo,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            auftragDetail712: null,
            auftragDetail714: null,
            device: null,
            emailObject: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            isReadingOk: false,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compErledigtIsDisabled() {
            return (
                this.actAuftragDetail.isExecutable === false ||
                ![202, 203].includes(this.auftragDetail714?.status) ||
                this.actAuftragDetail.status !== 0 ||
                this.isReadingOk === false
            );
        },
        compIsStepAlreadyDone() {
            return this.actAuftragDetail.status !== 0;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        await this.setActAuftragDetail(auftragDetail);
        this.device = (await devicesAPI.getSingle(auftragDetail.deviceID, { includeDeviceInfos: true })).data;
        this.emailObject = (
            await emailsAPI.sendEdmAusbauEmail(
                this.device.deviceID,
                this.createEmailPayload(constants.mail.PREVENT_SENDING_MAIL)
            )
        ).data;

        this.auftragDetail714 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            "7-1-4",
            this.$route.params.devicePoolId,
            this.device.deviceID
        );
        this.auftragDetail712 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            "7-1-2",
            this.$route.params.devicePoolId,
            this.device.deviceID
        );
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious() {
            this.$router.push({
                name: "execution-meters",
                params: {
                    orderId: this.$route.params.orderId,
                    devicePoolId: this.$route.params.devicePoolId,
                },
            });
        },
        async onClickRemoved() {
            const payloadAuftragDetails = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.DONE_CONDITION_1,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetails);
            // send email
            await this.sendEmail();
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        async onClickRemoveNotPossible() {
            const payload = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.BLOCKED,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payload).then(() => {
                // on SUCCESS, navigate to next step
                this.navigateToStepPrevious = this.actAuftragDetail.stepPrevious;
            });
        },
        async onClickMeterStays() {
            // Step 7-2-3 contains the new meter
            const auftragDetail723 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.$route.params.orderId,
                "7-2-3",
                this.$route.params.devicePoolId,
                null,
                this.$route.params.auftragItemId
            );
            // We don't need to get the entire object
            /*const newMeter = (await devicesAPI.getSingle(auftragDetail723.deviceID)).data;
            newMeter.internalNo = this.device.internalNo;*/
            // Copy all the values from the old meter to the new one #15404
            await devicesAPI.patch(auftragDetail723.deviceID, [
                {
                    op: "replace",
                    path: "/factoryNo",
                    value: this.device.factoryNo,
                },
                {
                    op: "replace",
                    path: "/internalNo",
                    value: this.device.internalNo,
                },
                {
                    op: "replace",
                    path: "/type",
                    value: this.device.type,
                },
                {
                    op: "replace",
                    path: "/deviceSuffix",
                    value: this.device.deviceSuffix,
                },
                {
                    op: "replace",
                    path: "/deviceType",
                    value: this.device.deviceType,
                },
                {
                    op: "replace",
                    path: "/description",
                    value: this.device.description,
                },
                {
                    op: "replace",
                    path: "/yearOfConstruction",
                    value: this.device.yearOfConstruction,
                },
                {
                    op: "replace",
                    path: "/telemeteringType",
                    value: this.device.telemeteringType,
                },
            ]);
            const payloadAuftragDetails = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.DONE_CONDITION_2,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetails);
            await this.sendEmail();
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        createEmailPayload(prevention) {
            return {
                prevention: prevention,
            };
        },
        async sendEmail() {
            if (
                this.device.deviceID &&
                (constants.env.NAME !== "localToDev" || constants.env.NAME !== "development") &&
                this.actAuftragDetail.status !== constants.auftragDetailStatus.DONE_CONDITION_1
            ) {
                await emailsAPI.sendEdmAusbauEmail(
                    this.device.deviceID,
                    this.createEmailPayload(constants.mail.SEND_MAIL)
                );
            } else {
                if (constants.env.NAME === "localToDev" || constants.env.NAME === "development") {
                    console.warn(`No mail will be sent. Wrong environment: ${constants.env.NAME}`);
                } else {
                    console.warn("No mail will be sent. Check DeviceInfo.IDSystemRolle", this.device.deviceID);
                }
            }
        },
        onIsReadingOk(val) {
            this.isReadingOk = val;
        },
    },
};
</script>
