<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <template v-if="device">
                <b-alert v-if="mspErrorMessage" variant="danger" show>
                    <strong>{{ mspErrorMessage }}</strong>
                </b-alert>
                <!-- ToDo(clarify): why do we hide everything else? -> maybe only a partial hide is required -->
                <div v-else>
                    <exec-meter-info
                        class="mb-2"
                        :mainDevice="device"
                        :order="order"
                        :devicePool="devicePool"
                        :category="'Funktionstest'"
                    />
                    <div class="font-weight-bold">Funktionstest</div>
                    <div class="mt-2">
                        <font-awesome-icon :icon="['fas', 'circle']" :class="getColorByStatus(smanOK)" />
                        Kommunikation MSP - SMAN
                    </div>
                    <div class="mt-2">
                        <font-awesome-icon :icon="['fas', 'circle']" :class="getColorByStatus(funktiontestOK)" />
                        Kommunikation SMAN - SMET
                    </div>
                    <div class="mt-2">
                        <font-awesome-icon :icon="['fas', 'circle']" :class="getColorByStatus(konfigOK)" />
                        Zählerparametrisierung passend
                    </div>
                    <div class="mt-2">
                        <font-awesome-icon
                            class="mr-1"
                            :icon="['fas', 'circle']"
                            :class="getColorByZaehlerstandablesungStatus(calculateZaehlerstandablesungState)"
                        />
                        Zählerstandablesung:
                        <span v-if="deviceMeterRegistersMsp.length" class="font-weight-bold font-italic text-black-50">
                            {{ deviceMeterRegistersMsp[0].date | formatDate("DD.MM.YYYY HH:mm") }}
                        </span>
                        <template v-else>-</template>
                        <!-- Meter registers list -->
                        <div v-if="deviceMeterRegistersMsp.length" style="margin-left: 50px">
                            <div
                                class="d-flex font-italic text-black-50"
                                v-for="dmr in deviceMeterRegistersMsp"
                                :key="dmr.obisCode"
                            >
                                <div>{{ dmr.identifier }}:</div>
                                <div v-if="dmr.isCalculated" class="ml-3" title="calculated value">
                                    <font-awesome-icon :icon="['fas', 'calculator']" />
                                </div>
                                <div class="ml-auto text-right">
                                    {{ printDecimal(dmr.value, dmr.decimalPlaces) }}
                                </div>
                                <div style="width: 100px" />
                            </div>
                        </div>
                    </div>
                    <!-- Print messages in the following row -->
                    <b-row class="mt-1">
                        <b-col>
                            <!-- No Obis code list from MSP -->
                            <b-col v-if="!hasMspObisCodes">
                                <b-alert variant="warning" show>
                                    <strong>Es wurden keine OBIS codes in MSP gefunden.</strong>
                                </b-alert>
                            </b-col>
                            <!-- Missing required obis codes -->
                            <div
                                v-if="deviceMeterRegistersMsp.length > 0 && calculateZaehlerstandablesungState !== 'ok'"
                            >
                                <b-alert variant="warning" show>
                                    <strong>
                                        OBIS code 1.8.0, 2.8.0 oder 1.8.1, 1.8.2, 2.8.1, 2.8.2 sind erforderlich.
                                    </strong>
                                </b-alert>
                            </div>
                            <!-- Warning: some values are calculated -->
                            <div v-if="deviceMeterRegistersMsp.length > 0 && areActiveEnergyTotalObisCodesCalculated">
                                <b-alert variant="info" show>
                                    <strong>Achtung: Einige Werte werden berechnet.</strong>
                                </b-alert>
                            </div>
                            <!-- No meter registers values from MSP -->
                            <b-col v-if="hasMspNoData">
                                <b-alert variant="warning" show>
                                    <strong>Leere Zählerregisterliste von MSP.</strong>
                                </b-alert>
                            </b-col>
                        </b-col>
                    </b-row>

                    <!-- Echtzeitdaten prüfen + MSP Ablesung starten -->
                    <b-row>
                        <b-col cols="2" />
                        <b-col>
                            <b-button
                                v-if="device"
                                class="mt-2 float-left"
                                @click="onClickStatusRefresh"
                                variant="primary"
                                :disabled="!device.factoryNo || isLoadingRegisters"
                            >
                                Seite/Status aktualisieren
                                <b-spinner v-if="isLoadingRegisters" small />
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-form-group
                        class="mt-3"
                        label-class="font-weight-bold pb-0"
                        v-if="device.requireWandler === false"
                    >
                        <template #label>
                            Erkennung Energieflussrichtung
                            <font-awesome-icon id="isPhaseCorrect" :icon="['fas', 'info-circle']" />
                            <b-tooltip target="isPhaseCorrect" triggers="click" title="Erkennung Energieflussrichtung">
                                <b>eRS801/eRS301</b><br />
                                Auf dem Zählerdisplay darf <b>keines</b> der Rücklieferungs-Symbole aktiv sein.<br />
                                <img
                                    height="60"
                                    src="@/assets/phasen_ruecklieferung.png"
                                    alt="Phasen der Rücklieferung"
                                />
                            </b-tooltip>
                        </template>
                        <b-form-checkbox v-model="isPhaseCorrect">
                            ist korrekt pro Phase L1, L2, L3
                        </b-form-checkbox>
                        <div class="mt-1 font-italic">
                            <strong>Hinweis:</strong> Bei Produktionsanlagen ist die Sicherung des Wechselrichters für
                            eine korrekte Ermittlung zu entfernen/auszuschalten.
                        </div>
                    </b-form-group>
                    <b-form-group label-class="font-weight-bold pb-0">
                        <template #label>
                            Erkennung Drehfeld <font-awesome-icon id="drehfeld" :icon="['fas', 'info-circle']" />
                            <b-tooltip target="drehfeld" triggers="click">
                                <b>eRS801/eRS301</b><br />
                                Bei einem falschen Drehfeld blinken alle Phasen-Symbole.<br />
                                <img height="53" src="@/assets/phasen_drehfeld_animated.gif" alt="Phasen Drehfeld" />
                            </b-tooltip>
                        </template>
                        <b-form-radio-group v-model="drehfeld" :state="compStateDrehfeld" stacked>
                            <b-form-radio value="1" plain>ist korrekt</b-form-radio>
                            <b-form-radio value="2">
                                ist falsch, da falsch am Geräteplatz verdrahtet (Korrektur nicht möglich)
                            </b-form-radio>
                            <b-form-invalid-feedback :state="compStateDrehfeld">
                                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                                Bitte eine Auswahl treffen.
                            </b-form-invalid-feedback>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-form-group label="Phasenanschluss" label-class="font-weight-bold pb-0">
                        <div v-if="phaseOptionsError.length" class="text-danger font-weight-bold">
                            {{ phaseOptionsError }}
                        </div>
                        <b-form-radio-group v-model="phase" :state="compStatePhase" stacked>
                            <b-form-radio v-for="po in phaseOptions" :key="po.text" :value="po.text">
                                {{ po.text }}
                            </b-form-radio>
                            <b-form-invalid-feedback :state="compStatePhase">
                                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                                Bitte eine Auswahl treffen.
                            </b-form-invalid-feedback>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-button variant="primary" @click="onClickSave" :disabled="compBtnSaveDisabled">
                        Speichern <b-spinner v-if="compIsBusyFormData" small />
                    </b-button>
                </div>
            </template>
            <ExecSectionErledigt
                :isBusy="isBusyErledigt"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import * as mathUtils from "@/utils/MathUtils";
import * as meterRegisterUtils from "@/utils/MeterRegisterUtils";
import constants from "@/constants/constants";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptions.api";
import deviceInfosAPI from "@/services/api/deviceInfos.api";
import devicesAPI from "@/services/api/devices.api";
import meterRegistersAPI from "@/services/api/meterRegisters.api";
import smartpowerApi from "@/services/api/smartpower.api";
import mspAPI from "@/services/api/msp.api";

export default {
    name: "Step_7_5_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecMeterInfo,
        ExecSectionErledigt,
    },
    props: {
        devicePool: {
            type: Object,
            default: null,
        },
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            areActiveEnergyTotalObisCodesCalculated: false, // false if 1.8.0/2.8.0 values are missing from MSP
            auftragDetail723: null, // Einbau SMET
            device: null,
            deviceInfoDrehfeld: null,
            deviceInfoPhase: null,
            deviceMeterRegistersMsp: [],
            drehfeld: null,
            phase: null,
            existingMeterRegisters: [],
            hasMspNoData: false,
            hasMspObisCodes: true,
            isBusyErledigt: false,
            isBusyFormDataDrehfeld: false,
            isBusyFormDataEnergieflussrichtung: false,
            isBusyFormDataPhasenanschluss: false,
            isLoadingRegisters: false,
            isMetersRead: false,
            isMspStatusRefreshed: false,
            isPhaseCorrect: false,
            meterFromMsp: null,
            mspErrorMessage: null,
            mspFromDate: null,
            mspObisCodes: [],
            mspToDate: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            phaseDefaultValue: "dreiphasig", // ExternalID: 3
            phaseOptions: [], // 1 = einphasig; 2 = zweiphasig; 3 = dreiphasig
            phaseOptionsError: "",
            phaseName: "Phasenanschluss",
            rpAuftragItemId: this.$route.params.auftragItemId,
            rpDevicePoolId: this.$route.params.devicePoolId,
            rpOrderId: this.$route.params.orderId,
            smanFromMsp: null,
            smartManagerFactoryNo: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compBtnSaveDisabled() {
            return (
                // requirement: #19477 (only possible to save if status is "IN PROGRESS")
                this.order.auftragStatusID.toUpperCase() !== "IN PROGRESS" ||
                this.compIsBusyFormData ||
                // drehfeld validation failed -> save is disabled (to prevent saving invalid data)
                this.compStateDrehfeld === false ||
                // phase validation failed -> save is disabled (to prevent saving invalid data)
                this.compStatePhase === false
            );
        },
        compIsBusyFormData() {
            return (
                this.isBusyFormDataDrehfeld ||
                this.isBusyFormDataEnergieflussrichtung ||
                this.isBusyFormDataPhasenanschluss
            );
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.mspErrorMessage) {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: `MSP - Fehlermeldung: '${this.mspErrorMessage}'`,
                });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "MSP - keine Fehlermeldung" });
            }
            if (this.smanOK === "ok") {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "MSP - Kommunikation 'MSP - SMAN': 'OK'",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: `MSP - Kommunikation 'MSP - SMAN': '${this.smanOK.toUpperCase()}'`,
                });
            }
            if (this.funktiontestOK === "ok") {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "MSP - Kommunikation 'SMAN - SMET': 'OK'",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: `MSP - Kommunikation 'SMAN - SMET': '${this.funktiontestOK.toUpperCase()}'`,
                });
            }
            if (this.konfigOK === "ok") {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "MSP - Zählerparametrisierung: 'OK'" });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: `MSP - Zählerparametrisierung: '${this.konfigOK.toUpperCase()}'`,
                });
            }
            if (this.calculateZaehlerstandablesungState === "ok") {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "MSP - Zählerstandablesung: 'OK'" });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: `MSP - Zählerstandablesung: '${this.calculateZaehlerstandablesungState.toUpperCase()}'`,
                });
            }
            if (this.isMspStatusRefreshed) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "MSP Daten: aktualisiert" });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "MSP Daten: Daten NICHT aktualisiert (bitte button 'Seite/Status aktualisieren' klicken)",
                });
            }
            if (this.hasMspObisCodes) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "MSP - OBIS codes: vorhanden" });
            } else {
                preconditionsLocalDetails.push({ status: "OPEN", message: "MSP - OBIS codes: NICHT vorhanden" });
            }
            if (this.hasMspNoData) {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "MSP - Zählerregisterdaten: NICHT vorhanden",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "MSP - Zählerregisterdaten: vorhanden",
                });
            }
            if (this.isPhaseCorrect) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Energieflussrichtung ist korrekt.",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Energieflussrichtung ist NICHT korrekt.",
                });
            }
            if (this.compStateDrehfeld === false) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Drehfeld: wurde NICHT bestimmt" });
            } else {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: `Drehfeld: ${this.drehfeld} (1 - korrekt, 2 - falsch)`,
                });
            }
            if (this.compStatePhase === false) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Phasenanschluss wurde NICHT bestimmt." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Phasenanschluss wurde bestimmt." });
            }
            if (this.compIsBusyFormData) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Formular-Daten werden gespeichert." });
            }

            return preconditionsLocalDetails;
        },
        compStateDrehfeld() {
            if (this.drehfeld) {
                // drehfeld is set -> return "null" so the font color stays how it is (black) and will not be changed to green (valid input)
                return null;
            } else {
                return false;
            }
        },
        compStatePhase() {
            if (this.phase) {
                // phase is set -> return "null" so the font color stays how it is (black) and will not be changed to green (valid input)
                return null;
            } else {
                return false;
            }
        },
        smanOK() {
            return this.smanFromMsp?.isOnline ? "ok" : "nok"; // can be "ok", "nok", "pending"
        },
        funktiontestOK() {
            return this.meterFromMsp?.deviceStatus?.connectionState ?? "nok"; // can be "ok", "nok", "pending"
        },
        konfigOK() {
            return this.meterFromMsp?.deviceStatus?.configState ?? "nok"; // can be "ok", "nok", "faulty"
        },
        /**
         * @returns "ok" if (1.8.0 and 2.8.0) or (1.8.1 and 1.8.2 and 2.8.1 and 2.8.2) exist, "nok" otherwise #14864
         */
        calculateZaehlerstandablesungState() {
            if (this.deviceMeterRegistersMsp) {
                const activeEnergyTotalObisCodes = ["1.8.0", "2.8.0"];
                let amountOfActiveEnergyTotalObisCodes = this.deviceMeterRegistersMsp
                    .map((deviceMeterRegister) => deviceMeterRegister.identifier)
                    .filter((obisCode) => activeEnergyTotalObisCodes.includes(obisCode)).length;

                const activeEnergyTariffObisCodes = ["1.8.1", "1.8.2", "2.8.1", "2.8.2"];
                let amountOfActiveEnergyTariffObisCodes = this.deviceMeterRegistersMsp
                    .map((deviceMeterRegister) => deviceMeterRegister.identifier)
                    .filter((obisCode) => activeEnergyTariffObisCodes.includes(obisCode)).length;

                const isZaehlerstandablesungValid =
                    activeEnergyTotalObisCodes.length === amountOfActiveEnergyTotalObisCodes ||
                    activeEnergyTariffObisCodes.length === amountOfActiveEnergyTariffObisCodes;
                return isZaehlerstandablesungValid ? "ok" : "nok";
            }
            return "nok";
        },
    },
    async mounted() {
        // get data with no "await" dependencies - BEGIN
        keyValueOptionsAPI
            .get("meter", "Phasenanschluss")
            .then((resp) => {
                this.phaseOptions = resp.data;
            })
            .catch(() => {
                this.phaseOptionsError = "Fehler beim Laden der Phasenanschluss-Optionen!";
            });
        // get data with no "await" dependencies - END

        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(this.rpOrderId, step, this.rpDevicePoolId, null, this.rpAuftragItemId)
            .then(async (auftragDetail) => {
                await this.setActAuftragDetail(auftragDetail);
                // check for ad.value and parse it
                if (this.actAuftragDetail.value) {
                    const parsedJSON = JSON.parse(this.actAuftragDetail.value);
                    this.isPhaseCorrect = parsedJSON?.isPhaseCorrect ?? false;
                }
            });
        devicesAPI
            .getSingle(this.actAuftragDetail.deviceID, { includeDeviceInfos: true })
            .then((resp) => {
                this.device = resp.data;

                this.deviceInfoDrehfeld = this.device.deviceInfos.find((di) => di.name === "Drehfeld");
                this.drehfeld = this.deviceInfoDrehfeld ? this.deviceInfoDrehfeld.value : null;
                this.deviceInfoPhase = this.device.deviceInfos.find((di) => di.name === this.phaseName);
                this.phase = this.deviceInfoPhase?.value ?? this.phaseDefaultValue;
            })
            .then(() => {
                // GET meter registers
                meterRegistersAPI.get({ deviceID: this.device.deviceID, readingDate: "last" }).then((resp) => {
                    this.existingMeterRegisters = resp.data;
                });
            })
            .then(() => {
                auftragDetailsAPI
                    .getByOrderIdAndStep(this.rpOrderId, "7-2-3", this.rpDevicePoolId, this.device.deviceID)
                    .then((resp) => {
                        this.auftragDetail723 = resp;
                        const ad723ExecTime = this.auftragDetail723.executionTime;
                        if (ad723ExecTime) {
                            this.mspFromDate = moment(ad723ExecTime)
                                .add(-15, "minutes") // Subtract 15 min #19172
                                .format("YYYY-MM-DDTHH:mm:ss");
                            // ToDo(clarify): shouldn't this be set to the end of day (EoD) of ad723ExecTime (instead of today EoD)?
                            this.mspToDate = moment().format("YYYY-MM-DDT23:59:00");
                        }
                    });
            })
            .then(async () => {
                const deviceInfo = this.device.deviceInfos.find((di) => di.name === "RS485.SMAN");
                if (deviceInfo?.value) {
                    this.smartManagerFactoryNo = deviceInfo.value;
                    await this.getSmanFromMsp();
                } else {
                    this.mspErrorMessage =
                        "Es ist kein SMAN an diesem Zähler angeschlossen. Bitte zu Schritt 7-2-4 zurückgehen.";
                }
            })
            .then(() => {
                // note: I had to move this here to avoid race condition regarding this.auftragDetail723.executionTime
                // ToDo: this is a good example of a bad practice and should be refactored
                // Read info from MSP
                this.onClickStatusRefresh();
            });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        getColorByStatus(status) {
            return status === "ok" ? "mr-1 text-success" : "mr-1 text-danger";
        },
        getColorByZaehlerstandablesungStatus(status) {
            if (status === "ok") {
                return "text-success";
            }
            if (this.smanOK !== "ok" || this.funktiontestOK !== "ok" || this.konfigOK !== "ok") {
                return "text-danger"; // Dirk 26.04.2023: if one of the dots above is red, this dot must be red as well
            }
            if (!this.hasMspObisCodes) {
                return "text-warning";
            }
            return "text-danger";
        },
        async onClickErledigt() {
            this.isBusyErledigt = true;

            await this.saveMeterRegisters();
            await this.saveFormData();

            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: 200,
                },
            ];
            auftragDetailsAPI
                .patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail)
                .then(() => {
                    // navigate to next step only on SUCCESS
                    this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                })
                .finally(() => {
                    this.isBusyErledigt = false;
                });
        },
        async saveFormData() {
            // Energieflussrichtung
            this.isBusyFormDataEnergieflussrichtung = true;
            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/value",
                    value: JSON.stringify({
                        isPhaseCorrect: this.isPhaseCorrect,
                    }),
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail).finally(() => {
                this.isBusyFormDataEnergieflussrichtung = false;
            });

            // drehfeld
            this.isBusyFormDataDrehfeld = true;
            if (this.deviceInfoDrehfeld) {
                // warning: deviceInfosAPI.patch returns response.data while deviceInfosAPI.post returns response
                // ToDo: #19894
                await deviceInfosAPI
                    .patch(this.deviceInfoDrehfeld.deviceInfoID, [
                        { op: "replace", path: "/value", value: this.drehfeld },
                    ])
                    .then((data) => {
                        this.deviceInfoDrehfeld = data;
                    })
                    .finally(() => {
                        this.isBusyFormDataDrehfeld = false;
                    });
            } else {
                await deviceInfosAPI
                    .post({
                        deviceID: this.device.deviceID,
                        name: "Drehfeld",
                        // the front-end has to send this so the backend can save it in the expected format/type
                        dataType: "Numerisch (Ganzzahl)",
                        value: this.drehfeld,
                        fromDate: this.device.fromDate,
                        toDate: this.device.toDate,
                    })
                    .then((resp) => {
                        this.deviceInfoDrehfeld = resp.data;
                    })
                    .finally(() => {
                        this.isBusyFormDataDrehfeld = false;
                    });
            }

            // phasenanschluss
            this.isBusyFormDataPhasenanschluss = true;
            if (this.deviceInfoPhase) {
                // warning: deviceInfosAPI.patch returns response.data while deviceInfosAPI.post returns response
                // ToDo: #19894
                await deviceInfosAPI
                    .patch(this.deviceInfoPhase.deviceInfoID, [{ op: "replace", path: "/value", value: this.phase }])
                    .then((data) => {
                        this.deviceInfoPhase = data;
                    })
                    .finally(() => {
                        this.isBusyFormDataPhasenanschluss = false;
                    });
            } else {
                await deviceInfosAPI
                    .post({
                        deviceID: this.device.deviceID,
                        name: this.phaseName,
                        dataType: "Alphanumerisch",
                        value: this.phase,
                        fromDate: this.device.fromDate,
                        toDate: this.device.toDate,
                    })
                    .then((resp) => {
                        this.deviceInfoPhase = resp.data;
                    })
                    .finally(() => {
                        this.isBusyFormDataPhasenanschluss = false;
                    });
            }
        },
        onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        onClickSave() {
            this.saveFormData();
        },
        async onClickStatusRefresh() {
            this.isMspStatusRefreshed = false;
            await this.getSmanFromMsp();
            await this.readMeterFromMSP();
            this.isMspStatusRefreshed = true;
        },
        async getSmanFromMsp() {
            this.smanFromMsp = (await smartpowerApi.getSmanMinimal(this.smartManagerFactoryNo)).data;
            this.meterFromMsp = this.smanFromMsp?.smartMeters?.find((smet) => smet.meterId === this.device.factoryNo);
        },
        async readMeterFromMSP() {
            this.isLoadingRegisters = true;
            // ToDo(clarify): why check on execution time? -> should be checked for status...
            // ToDo(clarify): make precondition config work for this step?
            // If step 7-2-3 is not executed, show warning
            if (!this.auftragDetail723?.executionTime) {
                this.$bvToast.toast(`Schritt 7-2-3 nicht ausgeführt. Mach das bitte vorher`, {
                    title: "Fehler",
                    variant: "warning",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
                this.isLoadingRegisters = false;
                return;
            }
            // If one of the dots above is red, do not check obis-codes/meter-registers
            if (this.smanOK !== "ok" || this.funktiontestOK !== "ok" || this.konfigOK !== "ok") {
                // let's wait a little, so we see the spinner moving (which shows the user that something is running)
                await new Promise((r) => setTimeout(r, 500));
                this.isLoadingRegisters = false;
                // ToDo: exiting like this with no info is a bad practice -> to be improved
                return;
            }
            // Check /obis-codes first
            const obisCodes = (await smartpowerApi.getObisCodes(this.device.factoryNo)).data;
            if (!obisCodes || obisCodes.length === 0) {
                this.hasMspObisCodes = false;
                this.isLoadingRegisters = false;
                // ToDo: exiting like this with no info is a bad practice -> to be improved
                return;
            }
            this.hasMspObisCodes = true;
            this.deviceMeterRegistersMsp = [];
            // ToDo: refactor this to use promise
            try {
                const response = await mspAPI.getMeterRegisterObisValues(
                    this.smartManagerFactoryNo,
                    this.device.factoryNo,
                    this.mspFromDate,
                    this.mspToDate
                );
                if (response && response.data && response.data.meterRegisterObisValues) {
                    // Add extra information
                    this.hasMspNoData = response.data.meterRegisterObisValues.length === 0;
                    for (const meterRegisterMsp of response.data.meterRegisterObisValues) {
                        meterRegisterMsp.value = (Math.round(meterRegisterMsp.value * 1000) / 1000).toFixed(3);
                        this.deviceMeterRegistersMsp.push({
                            ...meterRegisterMsp /* { obisCode: "1-0:5.8.1*255", unit: null, value: 0.1, date: ... } */,
                            /* from "1-0:1.8.1*255" to "1.8.1" - but WARNING meterRegisterMsp.obisCode could also be just "1.8.1"  */
                            identifier: meterRegisterUtils.getIdentifierLabel(meterRegisterMsp.obisCode),
                            /* from "1-0:1.8.1*255" to "1-0:1.8.1" - but WARNING meterRegisterMsp.obisCode could also be just "1.8.1"  */
                            obisCodeTrimmed: meterRegisterUtils.getObisLabel(meterRegisterMsp.obisCode),
                        });
                    }
                }
                // Check the presence of OBIS 1.8.0, 2.8.0
                if (!this.hasMspNoData) {
                    this.calculateActiveEnergyTotalObisCodes();
                }
            } finally {
                this.isLoadingRegisters = false;
            }
        },
        calculateActiveEnergyTotalObisCodes() {
            const obis180 = this.deviceMeterRegistersMsp.find((mr) => mr.identifier === "1.8.0");
            const obis280 = this.deviceMeterRegistersMsp.find((mr) => mr.identifier === "2.8.0");
            if (obis180 && obis280) {
                this.areActiveEnergyTotalObisCodesCalculated = false;
                return;
            }
            // Calculate OBIS 1.8.0 = 1.8.1 + 1.8.2
            if (!obis180) {
                const obis181 = this.deviceMeterRegistersMsp.find((mr) => mr.identifier === "1.8.1");
                const obis182 = this.deviceMeterRegistersMsp.find((mr) => mr.identifier === "1.8.2");
                if (obis181 && obis182) {
                    this.areActiveEnergyTotalObisCodesCalculated = true;
                    const obis180calculated = _.clone(obis181); // shallow clone
                    obis180calculated.obisCode = obis181.obisCode.replace("1.8.1", "1.8.0");
                    obis180calculated.identifier = meterRegisterUtils.getIdentifierLabel(obis180calculated.obisCode);
                    obis180calculated.obisCodeTrimmed = meterRegisterUtils.getObisLabel(obis180calculated.obisCode);
                    obis180calculated.value = mathUtils.sumStrings(obis181.value, obis182.value, 3);
                    obis180calculated.isCalculated = true;
                    this.deviceMeterRegistersMsp.push(obis180calculated);
                }
            }
            // Calculate OBIS 2.8.0 = 2.8.1 + 2.8.2
            if (!obis280) {
                const obis281 = this.deviceMeterRegistersMsp.find((mr) => mr.identifier === "2.8.1");
                const obis282 = this.deviceMeterRegistersMsp.find((mr) => mr.identifier === "2.8.2");
                if (obis281 && obis282) {
                    this.areActiveEnergyTotalObisCodesCalculated = true;
                    const obis280calculated = _.clone(obis281); // shallow clone
                    obis280calculated.obisCode = obis281.obisCode.replace("2.8.1", "2.8.0");
                    obis280calculated.identifier = meterRegisterUtils.getIdentifierLabel(obis280calculated.obisCode);
                    obis280calculated.obisCodeTrimmed = meterRegisterUtils.getObisLabel(obis280calculated.obisCode);
                    obis280calculated.value = mathUtils.sumStrings(obis281.value, obis282.value, 3);
                    obis280calculated.isCalculated = true;
                    this.deviceMeterRegistersMsp.push(obis280calculated);
                }
            }
        },
        printDecimal(number, decimalPlaces) {
            if (isNaN(number)) {
                return "Invalid number";
            }
            return new Intl.NumberFormat("de-CH", {
                minimumFractionDigits: decimalPlaces ?? 0,
                maximumFractionDigits: decimalPlaces ?? 0,
            }).format(number);
        },
        async saveMeterRegisters() {
            if (this.existingMeterRegisters.length === 0) {
                this.$bvToast.toast(
                    `Nichts zu speichern, überprüfen Sie MeterRegisters für Gerät ${this.device.deviceID}.`,
                    {
                        title: "Fehler",
                        variant: "warning",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    }
                );
                console.warn(`Nothing to save, check MeterRegisters for device ${this.device.deviceID}`);
            }
            // clone existing meter register and save or update (update in case of meterRegister=TODAY)
            for (const meterRegister of this.existingMeterRegisters) {
                const meterRegisterMsp = this.deviceMeterRegistersMsp.find(
                    (mr) => mr.identifier === meterRegister.identifier
                );
                if (!meterRegisterMsp) {
                    // console.log(
                    //     `MeterRegister.Identifier ${meterRegister.identifier} for Device ${this.device.deviceID} found in REtasks but not in MSP`
                    // );
                    continue;
                }
                // readingDate = TODAY --> overwrite
                if (moment(meterRegister.readingDate).isSame(moment(), "day")) {
                    const payloadMeterRegister = [
                        {
                            op: "replace",
                            path: "/readValue",
                            value: meterRegisterMsp.value,
                        },
                        {
                            op: "replace",
                            path: "/readingType",
                            value: constants.readingType.AUTO, // TODO: chiedere a Dirk
                        },
                        {
                            op: "replace",
                            path: "/readingDate",
                            value: moment().format(constants.DATE_FORMAT_ISO),
                        },
                    ];
                    await meterRegistersAPI.patch(meterRegister.meterRegisterID, payloadMeterRegister);
                } else {
                    // else clone and insert new meterRegister
                    const payloadMeterRegister = _.clone(meterRegister);
                    payloadMeterRegister.readValue = meterRegisterMsp.value;
                    payloadMeterRegister.readingType = constants.readingType.AUTO;
                    payloadMeterRegister.readingDate = moment().format(constants.DATE_FORMAT_ISO);
                    delete payloadMeterRegister.meterRegisterID;
                    delete payloadMeterRegister.creationName;
                    delete payloadMeterRegister.creationDate;
                    delete payloadMeterRegister.revisionName;
                    delete payloadMeterRegister.revisionName;
                    await meterRegistersAPI.post(payloadMeterRegister);
                }
            }
        },
    },
};
</script>
